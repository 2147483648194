<template>
  <div style="position: relative;">
    <div class="hero-container">
      <div v-for="(item, index) of contents" :key="index">
        <div @click="actCurrentItem(index)"
          :class="item.isActiveBorder ? [pickItemCode] : [pickItemCode, noSelectedBgColor]"></div>
      </div>
    </div>
    <div class="slider">
      <div v-for="(item, index) of contents" :key="index" :style="combinedStyle(index, contents.length)" class="slide" >
        <HeroSlide :database="FilteredData(item)" :isActive="currentIndexCode" :playerInstance="playerInstance"
          loading="lazy" @redirectToDetailPageChildCB="redirectToDetailPageChildCB"></HeroSlide>
      </div>
    </div>



  </div>
</template>

<script>
import Redirection from '@/mixins/redirection.js';

export default {
  mixins: [Redirection],
  data() {
    return {
      selectedItem: {},
      borderEnable: 'border-active',
      opacityBlur: 'opacity-blur',
      pickItemCode: 'pick-item-code',
      noSelectedBgColor: 'no-Selected-BgColor',
      currentIndexCode: 0,
      flagCurrentPoint: 0,
      transitionDuration: 1,
      intervalVarible: null,
      isDragging: false,
      recentMovementTimeout: false
    }
  },
  computed: {
 
  },
  props: ["contents", "playerInstance"],
  mounted() {
    this.sliderDrag();

    this.startInterval();

    setTimeout(() => {
      document.getElementById(`player_carousel_${this.currentIndexCode}`) && document.getElementById(`player_carousel_${this.currentIndexCode}`).play();
    }, 2000);

    this.selectedItem = this.FilteredData(this.contents[0])


    setTimeout(() => {
      document.getElementById(`player_carousel_${this.currentIndexCode}`)?.play();

    }, 2000);


  },
  methods: {
    combinedStyle(index, total) {
      let style = {
        transform: `translateX(-${this.flagCurrentPoint * 100}%)`, // Dynamic style
        flex: "0 0 auto",
        height: "100%",
        width: "100%",
      };
      if (this.flagCurrentPoint ==  0) {
        return { ...style, transition: `none !important`}
      } else {
        return { ...style, transition: `1s`}
      }
       
    },
    redirectToDetailPageChildCB (data) {

      console.error(this.recentMovementTimeout, "isDragging")
      if (!this.recentMovementTimeout) {
        this.stopInterval();
        this.redirectToDetailPage(data)
      }
    },
  startInterval() {
      if (this.intervalVariable) {
        clearInterval(this.intervalVariable); // Clear any existing interval
      }
      this.intervalVariable = setInterval(() => {
        this.flagCurrentPoint = (this.flagCurrentPoint + 1);
        this.actCurrentItem(this.flagCurrentPoint, "setInterval");
      }, 5000);
  },
  stopInterval() {
    if (this.intervalVariable) {
      clearInterval(this.intervalVariable); // Clear the interval
      this.intervalVariable = null; // Reset the interval ID
    }
  },
    sliderDrag() {
      const slider = document.querySelector('.slider');
      const slides = Array.from(document.querySelectorAll('.slide'));

      let startX = 0;


      const updateSlide = (index) => {
        if (index < 0) index = 0;
        if (index >= slides.length) index = 0;
        this.actCurrentItem(index , "updateSlide")
      }

      const handleStart = (e) => {
        console.log("handleStart", handleStart)
        const x = e.touches ? e.touches[0].pageX : e.pageX;
        startX = x;
        this.isDragging = true;
        slider.style.cursor = "grab"
        this.stopInterval();
      }

       const handleMove = (e) => {

        if (!this.isDragging) return;
        this.recentMovementTimeout = true;

        const x = e.touches ? e.touches[0].pageX : e.pageX;
        const moveX = x - startX;
        const threshold = 50;

        if (moveX > threshold) {
          updateSlide(this.flagCurrentPoint - 1);
          this.isDragging = false;
        } else if (moveX < -threshold) {
          updateSlide(this.flagCurrentPoint + 1);
          this.isDragging = false;
        }
        
      }

      // Handle end of drag or swipe
       const handleEnd = () => {
       
        this.isDragging = false;
        slider.style.cursor = "pointer"
        this.startInterval();
        setTimeout(() => {
          this.recentMovementTimeout = false;
        }, 100)

      }

      // Attach event listeners for mouse
      slider.addEventListener('mousedown', handleStart);
      slider.addEventListener('mousemove', handleMove);
      slider.addEventListener('mouseup', handleEnd);
      slider.addEventListener('mouseleave', handleEnd);

      // Attach event listeners for touch
      slider.addEventListener('touchstart', handleStart);
      slider.addEventListener('touchmove', handleMove);
      slider.addEventListener('touchend', handleEnd);
    },
    actCurrentItem(activeIndex, code) {

      this.flagCurrentPoint = (activeIndex) % (this.contents.length) ;
      activeIndex = (activeIndex) % (this.contents.length);

      this.selectedItem = {};
      this.currentIndexCode = activeIndex
      this.selectedItem = this.FilteredData(this.contents[activeIndex]);
      this.contents.map((item, index) => {
        if (activeIndex == index) {
          return item.isActiveBorder = true;
        } else {
          return item.isActiveBorder = false;
        }
      })

      setTimeout(() => {
        document.getElementById(`player_carousel_${this.currentIndexCode}`)?.play();
      }, 500)

    },
    videoEnded() {

      if (this.contents.length == this.currentIndexCode + 1) {
        this.currentIndexCode = 0;
      } else {

        this.currentIndexCode = this.currentIndexCode + 1;
      }
      this.actCurrentItem(this.currentIndexCode, "=====")
    },
    FilteredData(content) {
      let posterurl = this.getPoster(content);
      let description = content?.longdescription || content?.shortdescription;
      let title = content?.title;
      let videoUrl = content.trailer && content.trailer[0] && content.trailer[0].filelist && content.trailer[0].filelist[0].filename;
      let subgenre = content?.tags;
      let returnObj = {
        longdescription: content?.longdescription,
        longdescription: content?.shortdescription,
        "description": description,
        "sources": videoUrl,
        "thumb": posterurl,
        "title": title,
        "subgenre": subgenre,
        productionyear: content?.productionyear,
        duration: this.calcTimeFormat(content?.duration),
        advisory: content?.advisory,
        objectid: content?.objectid,
        category: content?.category,
        genre: content?.genre,
        contentlanguage: content?.contentlanguage[0],
        defaulttitle: content?.defaulttitle,
        pgrating: content?.pgrating,
        contentdetails: content?.contentdetails,
        poster: content?.poster,
        objecttype: content?.objecttype,
        playbacktype: content?.playbacktype,
        contentprovider: content?.contentprovider,
        channelno: content?.channelno,
        catchupperiod: content?.catchupperiod
      }
      // console.log("returnObj", returnObj)
      return returnObj;
    },
    calcTimeFormat(val) {
      if (!val) return;
      let totalTime = val;
      let hours = Math.floor(totalTime / 3600);
      let minutes = Math.floor((totalTime - hours * 3600) / 60);

      let showTime =
        (hours ? hours.toString().padStart(2, "0") + this.$t("hr") : "00h") +
        " " +
        (minutes ? minutes.toString().padStart(2, "0") + (minutes === 1 ? this.$t("min") : this.$t("mins")) : "00s");

      if (showTime == " ") {
        return val + this.$t("secs");
      } else {
        return showTime;
      }
    },
    getPoster(content) {
      if (Array.isArray(content.poster)) {
        if (content.poster && content.poster.length) {
          let index = content.poster.findIndex((element) => {
            return (element.quality === "HD" || element.quality === "SD") && element.postertype === "LANDSCAPE";
          });
          if (index > -1) {

            if (content.poster[index].filelist.length >= 2) {
              return content.poster[index].filelist[1].filename;
            } else {
              return content.poster[index].filelist[0].filename;
            }

          }
        }
      } else {
        if (content.thumbnail) {
          return content.thumbnail;
        }
      }
    },
  },
  components: {
    HeroSlide: () => import('@/themeearth/components/dashboard/herocarousel/HeroComponentSlide.vue')
  }
}
</script>

<style scoped lang="scss">
.hero-container {
  position: absolute;
  margin: 0 auto;
  padding: 0rem;
  display: flex;
  -moz-column-gap: 1rem;
  column-gap: 0.5rem;
  z-index: 99;

  div {
    div {
      cursor: pointer;
      //width: 0.8rem;
      width: 1.7rem;
      cursor: pointer;
      //height: 0.8rem;
      height: 0.4rem;
      //border: 0.15rem solid white;
      border: 0.1rem solid rgb(195 192 192 / 50%);
      //border-radius: 50%;
      border-radius: 4px;

      img {
        width: 100%
      }
    }
  }

}

.swiper-slide img {
  height: 6rem;
  width: 8rem;
  opacity: 1;
  border-radius: 5px;
}

.swiper-slide img:hover {
  transform: scale(1.1);
  z-index: 99;
  border: 1px solid;
  cursor: pointer;
  opacity: 1.0;

}

.swiper-button-next {
  transform: scale(0.6);
  right: -5px;
}

.swiper-button-prev {
  transform: scale(0.6);
  left: -5px;
}

.border-active {
  border: 2px solid white;
  opacity: 1 !important;
}

.opacity-blur {
  opacity: 0.8;
}

.opacity-fill {
  opacity: 1;
}

.swiper-wrapper {
  padding: 0.2rem;
  align-items: center;
  gap: 0.5rem;
}


.mySwiper {
  padding: 1rem 0;
}

.pick-item-code {
  background: red;
}

.no-Selected-BgColor {
  background: transparent;
}

.slider {
  width: 100%;
  display: flex;
  cursor: pointer;
  height: 100%;
  overflow: hidden;
  min-height: 30rem;
}

@media screen and (max-width:600px) {
  .slider {
    min-height: 20rem;
  }
}

@media screen and (max-width:1023px) {
  .hero-container {
      margin: auto;
      justify-content: center;
      bottom: 10%;
      width: 100%;
      div {
        div {
          width: 0.5rem;
          cursor: pointer;
          height: 0.5rem;
          border: 0.1rem solid rgba(195, 192, 192, 0.5);
          border-radius: 50%;
        }
      }
  }
}

@media screen and (min-width:1024px) {
  .hero-container {
    right: 2%;
    bottom: 20%;
  }
}
</style>